<template>
  <div class="nav">
    <div
      :class="['first-level', item.status == 'expanded' ? 'expanded' : '']"
      v-for="(item, index) in menu"
      :key="item.url"
    >
      <span class="fw500" @click="setActive(index)"
        >{{ item.label }}<i class="iconfont"
      /></span>
      <div class="second-level">
        <span
          :key="second.url"
          @click="setViewLink(second.url)"
          v-for="second in item.children"
          :class="showLink === second.url ? 'active' : ''"
          >{{ second.label }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
// 展示链接，默认about-artemis-ads
const showLink = ref('about-artemis-ads')
const menu = ref([
  {
    status: 'expanded',
    label: 'About Artemis Ads',
    url: 'about-artemis-ads-home',
    children: [
      {
        label: 'About Artemis Ads',
        url: 'about-artemis-ads', // finished 1 - 26
      },
      {
        label: 'Why Join Artemis Ads',
        url: 'why-join-artemis-ads', // finished 2 - 26
      },
      {
        label: 'How to Join Artemis Ads as a Brand',
        url: 'how-to-join-artemis-ads-as-a-brand', // finished 3 - 26
      },
      {
        label: 'Artemis Ads Pricing Structure',
        url: 'artemis-ads-pricing-structure', //  finished 4 - 26
      },
      {
        label: 'Attribution Data Access Authorization',
        url: 'attribution-data-access-authorization', // finished 5 - 26
      },
      {
        label: 'Earning Amazon Brand Referral Bonus',
        url: 'earning-amazon-brand-referral-bonus', // finished img style 6 - 26
      },
      {
        label: 'Brand Referral Bonus Rates by Category',
        url: 'brand-referral-bonus-rates-by-category', // 7 - 26
      },
      {
        label: 'Amazon Attribution Model',
        url: 'amazon-attribution-model', // finished 8 - 26
      },
      {
        label: 'Amazon Attribution and Sales Reporting',
        url: 'amazon-attribution-and-sales-reporting', // finished 9 - 26
      },
      {
        label: 'Promotion Requirements on Artemis Ads',
        url: 'promotion-requirements-on-artemis-ads', // finished 10 - 26
      },
      {
        label: 'Multi-Brand and Multi-Product Promotions',
        url: 'multi-brand-and-multi-product-promotions', // finished 11 - 26
      },
      {
        label: 'Where Will Promotion Posts Be Displayed',
        url: 'where-will-promotion-posts-be-displayed', // finished 12 - 26
      },
    ],
  },
  {
    label: 'For Seller',
    url: 'for-seller',
    children: [
      {
        label: 'Seller General Account Setup',
        url: 'seller-general-account-setup', // finished img style 13 - 26
      },
      {
        label: 'Activate Brand(s)',
        url: 'activate-brands', // finished img style 14 - 26
      },
      {
        label: 'Activate Product(s)',
        url: 'activate-products', // finished 15 - 26
      },
      {
        label: 'Manage Multiple Sellers',
        url: 'manage-multiple-sellers', // finished img style 16 - 26
      },
      {
        label: 'Manage Deals in Bulk',
        url: 'manage-deals-in-bulk', // finished img style 17 - 26
      },
      {
        label: 'Manage Products in Bulk',
        url: 'manage-products-in-bulk', // finished img style 18 - 26
      },
      {
        label: 'Amazon Attribution Service',
        url: 'amazon-attribution-service', // finished 19 - 26
      },
      {
        label: 'Amazon Attribution Link',
        url: 'amazon-attribution-link', // finished 20 - 26
      },
      {
        label: 'Brand Referral Bonus',
        url: 'brand-referral-bonus', // finished 21 - 26
      },
    ],
  },
  {
    label: 'For Publisher',
    url: 'for-publisher',
    children: [
      {
        label: 'Getting Started with Artemis Ads',
        url: 'getting-started-with-artemis-ads', // finished 22 - 26
      },
      {
        label: 'Commissions and Sales Tracking',
        url: 'commissions-and-sales-tracking', // finished 23 - 26
      },
      {
        label: 'Promotions and Global Reach',
        url: 'promotions-and-global-reach', // finished 24 - 26
      },
      {
        label: 'Creating Engaging and Trustworthy Content',
        url: 'creating-engaging-and-trustworthy-content', // finished 25 - 26
      },
      {
        label: 'Payments and Support',
        url: 'payments-and-support', // finished 26 - 26
      },
    ],
  },
])

// 设置一级分类展开、收起状态
function setActive(index) {
  menu.value[index].status =
    menu.value[index].status == 'expanded' ? '' : 'expanded'
}

function setViewLink(url) {
  showLink.value = url
  router.push(`/help/${url}`)
  document.querySelector('.help-page').scrollIntoView()
}

onBeforeMount(() => {
  const currentPath = router.currentRoute.value.path.replace('/help/', '')

  if (currentPath) {
    let current = []

    showLink.value = currentPath

    for (let i = 0; i < menu.value.length; i++) {
      for (let j = 0; j < menu.value[i].children.length; j++) {
        if (currentPath == menu.value[i].children[j].url) {
          current = [i, j]
          break
        }
      }
      if (current.length) {
        break
      }
    }

    for (let i = 0; i < menu.value.length; i++) {
      menu.value[i].status = i === current[0] ? 'expanded' : ''
    }
  }
})
</script>

<style lang="less" scoped>
.nav {
  flex: 0 0 320px;
  padding: 24px;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.07);
  span {
    cursor: pointer;
    color: var(--dark-1);
  }
  .first-level {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    > span {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      justify-content: space-between;
      > i {
        line-height: 16px;
        &:before {
          display: block;
          font-size: 14px;
          content: '\e75f';
          font-style: normal;
          border-radius: unset;
          font-family: iconfont;
          background-color: unset;
          transform: rotate(-90deg);
          transition: transform ease 0.2s;
        }
      }
    }
  }
  .expanded {
    > span > i::before {
      transform: none;
    }
    .second-level {
      display: block;
    }
  }
  .second-level {
    display: none;
    margin-top: 8px;
    padding-left: 16px;
    > span {
      display: block;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 20px;
      margin-left: -16px;
      &.active {
        font-weight: 500;
        border-radius: 8px;
        position: relative;
        background: #ebe4ff;
        color: var(--primary-text);
        &:before {
          left: 0;
          top: 8px;
          width: 3px;
          bottom: 8px;
          content: '';
          position: absolute;
          border-radius: 4px;
          background: var(--primary);
        }
      }
    }
  }
}
</style>